import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
// import { connectFunctionsEmulator } from 'firebase/functions'

console.log({ firebase })

const ourRegion = 'asia-northeast1'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

firebase.initializeApp(firebaseConfig)

const firebaseAuth = firebase.auth()
const firebaseStorage = firebase.storage()
const firebaseFirestore = firebase.firestore()
const firebaseFunctions = firebase.app().functions(ourRegion)
// connectFunctionsEmulator(firebaseFunctions, '0.tcp.in.ngrok.io', 10574)

firebaseAuth.languageCode = 'ja'

export { firebase, firebaseAuth, firebaseStorage, firebaseFirestore, firebaseFunctions }
