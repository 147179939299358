import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'
import axios from 'axios'
import stripe from 'stripe'
import { shopCollection } from '@shared/collections/shop.collection'

const stripeClient = stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
const baseUrl =
  process.env.VUE_APP_ENVIRONMENT === 'development' ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL_PRO
const browserLocale = navigator.language.split('-')[0]

const formatDateTime = (dateTime) => {
  return browserLocale === 'ja'
    ? format(dateTime, 'yyyy/MM/dd (eee) HH:mm', { locale: ja })
    : format(dateTime, 'yyyy/MM/dd (eee) HH:mm')
}

async function uploadFile(file) {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)

      reader.onload = async () => {
        const fileData = new Uint8Array(reader.result)
        try {
          const upload = await stripeClient.files.create({
            file: {
              data: fileData,
              name: file.name,
              type: 'application/octet-stream'
            },
            purpose: 'identity_document'
          })
          console.log('File uploaded successfully:', upload)
          resolve(upload)
        } catch (error) {
          console.log('Error uploading file', error)
          reject(error)
        }
      }

      reader.onerror = (error) => {
        console.error('File reading error', error)
        reject(error)
      }
    })
  } catch (error) {
    console.log('Error uploading file', error)
    throw error
  }
}

async function getShopOwnerById(id) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios(`${baseUrl}/getShopOwner?shopOwnerId=${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

async function getBalanceByStripeApi(stripeAccountId) {
  console.log('getBalanceByStripeApi called with stripeAccountId:', stripeAccountId)
  try {
    const response = await axios.post(`${baseUrl}/getBalanceByStripeApi`, {
      stripeAccountId: stripeAccountId
    })
    console.log('Response from getBalanceByStripeApi:', response.data)
    return response.data
  } catch (error) {
    console.error('Error in getBalanceByStripeApi:', error)
    throw error
  }
}
async function payoutByStripeApi(payload) {
  console.log('payoutByStripeApi called with payload:', payload)
  try {
    const response = await axios.post(`${baseUrl}/payOutStripeApi`, payload)
    console.log('Response from payoutByStripeApi:', response.data)
    return response.data
  } catch (error) {
    console.error('Error in payoutByStripeApi:', error)
    throw error
  }
}
async function getPayoutListByStripeApi(payload) {
  console.log('payoutByStripeApi called with payload:', payload)
  try {
    const response = await axios.post(`${baseUrl}/getPayOutLisStripeApi`, payload)
    console.log('Response from payoutByStripeApi:', response.data)
    return response.data
  } catch (error) {
    console.error('Error in payoutByStripeApi:', error)
    throw error
  }
}
async function shopOwnerUpdate(payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.put(`${baseUrl}/updateShopOwner`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

async function getStripeRedirect(payload) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.put(`${baseUrl}/stripeOnBoarding`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}
async function isShopDeleted(shopId, router) {
  try {
    const shop = await shopCollection.find(shopId)
    if (shop && shop.status === 'deleted') {
      // Redirect to 'sign-up' route if the shop is deleted
      console.log('is deleted')
      router.push({ name: 'sign-up' })
      return false // Indicate that the shop is deleted
    }
    return true // Indicate that the shop is not deleted
  } catch (error) {
    console.error('==>Error checking shop status:', error)
    return false // Handle error, you might want to redirect or notify the user
  }
}

export {
  browserLocale,
  formatDateTime,
  getShopOwnerById,
  getBalanceByStripeApi,
  payoutByStripeApi,
  getPayoutListByStripeApi,
  shopOwnerUpdate,
  uploadFile,
  getStripeRedirect,
  isShopDeleted
}
