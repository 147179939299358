import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as navDrawer from './modules/navDrawer'
import * as shop from './modules/shop'
import * as user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: {
      namespaced: true,
      ...user
    },
    shop: {
      namespaced: true,
      ...shop
    },
    navDrawer: {
      namespaced: true,
      ...navDrawer
    }
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})

export default store
