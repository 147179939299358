import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/HomePage.vue')
  },
  {
    path: '/shops/:shopid',
    name: 'home',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/HomePage.vue')
  },
  {
    path: '/sign-in',
    name: 'signIn',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue')
  },
  {
    path: '/sign-out',
    name: 'signOut',
    meta: { enableAppBar: false, signInRequired: false, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignOut.vue')
  },
  {
    path: '/shop/update',
    name: 'updateShop',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateShop.vue')
  },
  {
    path: '/products',
    name: 'products',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/ProductsPage.vue')
  },
  {
    path: '/products/create',
    name: 'createProduct',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateProduct.vue')
  },
  {
    path: '/products/:productId/update',
    name: 'updateProduct',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/CreateProduct.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/OrdersPage.vue')
  },
  {
    path: '/orders/:orderId',
    name: 'orderDetails',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/OrderDetails.vue')
  },
  {
    path: '/stripe/:stripeAccountId',
    name: 'stripe',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/StripePage.vue')
  },
  {
    path: '/payout',
    name: 'payout',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "payout" */ '../views/PayoutPage.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "signIn" */ '../views/StatisticsPage.vue')
  },
  {
    path: '/send-sms',
    name: 'sendSms',
    meta: { enableAppBar: false, signInRequired: true, paymentRequired: true },
    component: () => import(/* webpackChunkName: "sendSms" */ '../views/SendSms.vue')
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  next()
  console.log('shop====== 1 is deleted', store.state.shop)
  if (to.path === '/' || to.path === '/shops' || to.path === '/stripe') {
    if (!store.state.user.userId) {
      console.log('shop====== 2 is deleted', store.state.shop)
      next('/sign-in')
      return
    }
    next(`shops/${store.state.shop.shop.uniqueId}`)
  }
})
router.addRoutes([
  {
    path: '*',
    redirect: '/404'
  }
])

export default router
